import React, { useState } from "react";
import { ReactComponent as Logo } from "../resources/logo.svg";
import { Link, withRouter } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Nav = () => {
  const [showMenu, setShowMenu] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 750px)" });
  const toggle = () => {
    window.document.body.style.overflow = !showMenu ? "hidden" : null;
    setShowMenu(!showMenu);
  };
  let close = () => {};
  if (isSmallScreen) {
    close = () => {
      window.document.body.style.overflow = null;
      setShowMenu(false);
    };
  }
  return (
    <>
      {showMenu && (
        <div style={{ height: 80, background: "transparent" }}></div>
      )}
      <div className={`navbar ${showMenu ? "hide" : "show"}`}>
        <div className="navbar-container">
          <Link onClick={close} className="home-link" to="/">
            <Logo className="home-link-logo" />
            Channel
          </Link>
          {isSmallScreen ? (
            <div
              onClick={toggle}
              style={{ paddingRight: 0, marginRight: 0 }}
              className="nav-link"
            >
              {showMenu ? "Close" : "Menu"}
            </div>
          ) : (
            <>
              <Link className="nav-link" to="/promises">
                Promises
              </Link>
              <Link className="nav-link" to="/articles">
                Articles
              </Link>
              <Link className="nav-link" to="/about">
                About
              </Link>
            </>
          )}
        </div>
        <div
          onClick={close}
          className={`mobile-menu ${showMenu ? "show" : "hide"}`}
        >
          <Link className="nav-link" to="/promises">
            Promises
          </Link>
          <Link className="nav-link" to="/articles">
            Articles
          </Link>
          <Link className="nav-link" to="/about">
            About
          </Link>
        </div>
      </div>
    </>
  );
};

export default withRouter(Nav);
