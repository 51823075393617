import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { IntercomAPI } from "react-intercom";

export default () => {
  useEffect(() => {
    IntercomAPI("update", { hide_default_launcher: true });
    return () => {
      IntercomAPI("update", { hide_default_launcher: false });
    };
  }, []);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [sending, setSending] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  useEffect(() => {
    window.analytics.page("Android");
  }, []);
  const handleEmail = (e) => {
    const emailText = e.target.value;
    setIsValidEmail(
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(emailText)
    );
    setEmail(emailText);
  };
  const isSmallScreen = useMediaQuery({ query: "(max-width: 750px)" });
  return (
    <>
      <div id="home-page">
        <Helmet>
          <title>Channel - Android Signup</title>
          <link rel="canonical" href="https://channel.af/android" />
        </Helmet>
        {emailSubmitted ? (
          <>
            <h1 className="main-title">Signed up!</h1>
            <h3 className="description">
              We’ll send you an email when it’s ready. Thanks for your support!
            </h3>
          </>
        ) : (
          <>
            <h1
              className="main-title"
              style={{ marginLeft: 16, marginRight: 16 }}
            >
              Get notified when we
              <br />
              launch our Android app
            </h1>
            <div style={{ marginBottom: 32 }}>
              <input
                value={email}
                onChange={handleEmail}
                style={{
                  border: "none",
                  backgroundImage: "none",
                  backgroundColor: "#2E2E2E",
                  borderRadius: 4,
                  paddingTop: "16px",
                  width: isSmallScreen ? "315px" : "343px",
                  paddingBottom: "16px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  boxShadow: "none",
                  color: "white",
                  fontSize: "16px",
                  lineHeight: "24px",
                  outline: 0,
                }}
                placeholder="Email"
              />
            </div>
            <div
              onClick={() => {
                if (isValidEmail && !sending) {
                  setSending(true);
                  fetch(
                    "https://api-r5xcurhn6a-uc.a.run.app/api/v1/android-subscribe",
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                      },
                      body: JSON.stringify({
                        android_email_notify: email,
                      }),
                    }
                  )
                    .then((res) => res.json())
                    .then((data) => {
                      if (data.created) {
                        setEmailSubmitted(true);
                      }
                      setSending(false);
                    });
                }
              }}
              style={{
                cursor: isValidEmail ? "pointer" : "not-allowed",
                margin: "0 auto",
                fontWeight: "500",
                paddingTop: "12px",
                paddingBottom: "12px",
                paddingLeft: "12px",
                paddingRight: "12px",
                width: isSmallScreen ? "315px" : "343px",
                marginBottom: "300px",
                fontSize: "16px",
                lineHeight: "24px",
                textAlign: "center",
                letterSpacing: "0.04em",
                textTransform: "uppercase",
                color: isValidEmail ? "black" : "rgba(255, 255, 255, 0.38)",
                backgroundColor: isValidEmail ? "#BB86FC" : "#2E2E2E",
                borderRadius: "100px",
              }}
            >
              continue
            </div>
          </>
        )}
      </div>
    </>
  );
};
