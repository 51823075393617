import React, { useEffect } from "react";
import { ReactComponent as AppStoreButton } from "../resources/app-store-button.svg";
import { ReactComponent as Wave } from "../resources/wave.svg";
import { ReactComponent as BottomWave } from "../resources/bottom-wave.svg";
import { ReactComponent as Derp } from "../resources/derp.svg";
import { ReactComponent as Dandy } from "../resources/dandy.svg";
import { ReactComponent as DownArrow } from "../resources/down-arrow.svg";
import { ReactComponent as ComicSeparator } from "../resources/comic-separator.svg";
import { ReactComponent as SquiggleSeparator } from "../resources/squiggle-separator.svg";
import dandyAds from "../resources/comics/dandy-ads.png";
import dandyAutoplay from "../resources/comics/dandy-autoplay.png";
import dandyEmail from "../resources/comics/dandy-email.png";
import dandyFriction from "../resources/comics/dandy-friction.png";
import dandyInterest from "../resources/comics/dandy-interest.png";
import dandyNotification from "../resources/comics/dandy-notification.png";
import dandyPersonal from "../resources/comics/dandy-personal.png";
import dandyScroll from "../resources/comics/dandy-scroll.png";
import derpAds from "../resources/comics/derp-ads.png";
import derpAutoplay from "../resources/comics/derp-autoplay.png";
import derpEmail from "../resources/comics/derp-email.png";
import derpFriction from "../resources/comics/derp-friction.png";
import derpInterest from "../resources/comics/derp-interest.png";
import derpNotification from "../resources/comics/derp-notification.png";
import derpPersonal from "../resources/comics/derp-personal.png";
import derpScroll from "../resources/comics/derp-scroll.png";
import BottomNav from "../components/BottomNav";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as MobileWave } from "../resources/mobile-wave.svg";
import { ReactComponent as MobileBottomWave } from "../resources/mobile-bottom-wave.svg";
import { ReactComponent as MobileSquiggle } from "../resources/mobile-squiggle.svg";
import { Helmet } from "react-helmet";

const comics = [
  {
    derp: {
      title: (
        <>
          Let me just check this one
          <br />
          notification...
        </>
      ),
      mobileTitle: "Let me just check this one notification...",
      image: derpNotification,
      text: (
        <>
          This is annoying, why am I getting a notification on my phone about
          discounted glasses, I don't even wear glasses. Let me close this
          notification, well, while I'm here I might as well
          check&nbsp;Instagram...
        </>
      ),
    },
    dandy: {
      title: (
        <>
          I only want to get notifications I<br />
          care about
        </>
      ),
      mobileTitle: "I only want to get notifications I care about",
      image: dandyNotification,
      text: (
        <>
          With Channel, I was able to turn off distracting notifications to my
          phone. I'm talking about you, TikTok. Now, I'm only allowing
          notifications I care about, texts from my family and&nbsp;friends.
        </>
      ),
    },
  },
  {
    derp: {
      title: (
        <>
          Checking my email feels like a game
          <br />
          &nbsp;
        </>
      ),
      mobileTitle: "Checking my email feels like a game",
      image: derpEmail,
      text: (
        <>
          I have 327 unread emails. Time to go to battle and conquer these
          messages. Okay, let me try and delete the easy ones. I don't need this
          Etsy email about... wait, a knitted hat that looks like a bear cub. I
          need to check&nbsp;this.
        </>
      ),
    },
    dandy: {
      title: (
        <>
          My email inbox is something I look
          <br />
          forward to opening
        </>
      ),
      mobileTitle: "My email inbox is something I look forward to opening",
      image: dandyEmail,
      text: (
        <>
          With Channel, I'm able to easily unsubscribe from spam emails -
          keeping only emails I care&nbsp;about.
        </>
      ),
    },
  },
  {
    derp: {
      title: (
        <>
          I want apps to know me better
          <br />
          than I do
        </>
      ),
      mobileTitle: "I want apps to know me better than I do",
      image: derpPersonal,
      text: (
        <>
          I like that when I'm on Amazon it knows all the products I've looked
          at and suggests more things for me to buy. Maybe if I buy this foot
          spa with heated bubbles I'll finally be&nbsp;happy.
        </>
      ),
    },
    dandy: {
      title: (
        <>
          I like being able to feel good about my
          <br />
          purchases in apps
        </>
      ),
      mobileTitle: "I like being able to feel good about my purchases in apps",
      image: dandyPersonal,
      text: (
        <>
          With Channel, I'm able to turn off the ability for apps to track my
          browsing and watch history, giving me back the control in what I watch
          and buy. Goodbye, manipulative&nbsp;algorithms.
        </>
      ),
    },
  },
  {
    derp: {
      title: (
        <>
          Let me keep watching videos without
          <br />
          doing anything
        </>
      ),
      mobileTitle: "Let me keep watching videos without doing anything",
      image: derpAutoplay,
      text: (
        <>
          Netflix is great because it automatically plays the next episode in
          the tv show without me having to do anything. I totally wanted to stay
          up until 4 in the morning finishing a&nbsp;series.
        </>
      ),
    },
    dandy: {
      title: (
        <>
          I'm able to have more self-control in
          <br />
          watching videos
        </>
      ),
      mobileTitle: "I'm able to have more self-control in watching videos",
      image: dandyAutoplay,
      text: (
        <>
          With Channel, I have to manually play the next video on Netflix,
          Youtube, etc. Sure it's a bit more of a pain, but at least now I don't
          go down 5&#8209;hour binge&#8209;watching&#8209;rabbit&#8209;holes.
        </>
      ),
    },
  },
  {
    derp: {
      title: (
        <>
          Newsfeeds are great because they
          <br />
          provide endless entertainment
        </>
      ),
      mobileTitle:
        "Newsfeeds are great because they provide endless entertainment",
      image: derpScroll,
      text: (
        <>
          I like going on websites like Facebook to check in on my Aunt Deborah,
          and then finding myself scrolling on the newsfeed for 30 minutes. Now
          I know how to turn a fantasy drawing into a dessert,
          thanks&nbsp;internet.
        </>
      ),
    },
    dandy: {
      title: (
        <>
          Wait, what if I just removed all
          <br />
          newsfeeds?
        </>
      ),
      mobileTitle: "Wait, what if I just removed all newsfeeds?",
      image: dandyScroll,
      text: (
        <>
          With Channel, I'm able to remove stress-scrolling newsfeeds from
          Facebook, Linkedin, etc. with a single tap. Now when I want to check
          in on Aunt Deborah, I just search her name. Much
          more&nbsp;intentional!
        </>
      ),
    },
  },
  {
    derp: {
      title: (
        <>
          I like the things I like and never want
          <br />
          to broaden my interests
        </>
      ),
      mobileTitle:
        "I like the things I like and never want to broaden my interests",
      image: derpInterest,
      text: (
        <>
          The thing I like about Youtube is that after I watch a few videos the
          app changes completely only showing recommended videos that they know
          I'll like. Now my opinions will never&nbsp;change!
        </>
      ),
    },
    dandy: {
      title: (
        <>
          I like going to YouTube and having a
          <br />
          purpose and knowing when to leave
        </>
      ),
      mobileTitle:
        "I like going to YouTube and having a purpose and knowing when to leave",
      image: dandyInterest,
      text: (
        <>
          I feel like I have a superpower, but now I don't have the same price
          to&nbsp;pay.
        </>
      ),
    },
  },
  {
    derp: {
      title: (
        <>
          I like when ads are tailored to
          <br />
          my interests
        </>
      ),
      mobileTitle: "I like when ads are tailored to my interests",
      image: derpAds,
      text: (
        <>
          The great thing about modern ads is that they really get me. Instead
          of seeing ads for things that are not relevant to me I'm able to see
          an ad for bucket hats that I can put my face on. Sure I have hats
          already, but I NEED&nbsp;them!
        </>
      ),
    },
    dandy: {
      title: (
        <>
          I've stopped ad tracking and
          <br />
          personalization
        </>
      ),
      mobileTitle: "I've stopped ad tracking and personalization",
      image: dandyAds,
      text: (
        <>
          With Channel, I was able to able to browse the internet without
          worrying about being overly tracked and exploited by ads. Now I think,
          "what would the ideal Dandy&nbsp;do?"
        </>
      ),
    },
  },
  {
    derp: {
      title: (
        <>
          I want everything to be as easy as
          <br />
          possible, 1-click everything
        </>
      ),
      mobileTitle:
        "I want everything to be as easy as possible, 1-click everything",
      image: derpFriction,
      text: (
        <>
          I can't believe there was a time when I had to physically go to a
          store or spent a few minutes buying something online. Next step,
          automatically buying and sending me things when they enter
          my&nbsp;brain.
        </>
      ),
    },
    dandy: {
      title: (
        <>
          I want to add a little friction, allowing
          <br />
          for a moment of pause
        </>
      ),
      mobileTitle:
        "I want to add a little friction, allowing for a moment of pause",
      image: dandyFriction,
      text: (
        <>
          With Channel, I've been able to add back some positive friction into
          my life. I've done things like removed 1&#8209;click purchasing,
          hidden my inbox, and hidden my Slack channels. Now I'm more
          intentional with my&nbsp;apps.
        </>
      ),
    },
  },
];
const ComicPane = ({ comic }) => (
  <>
    <ComicSeparator width="100%" class="comic-separator" />
    <div style={{ maxWidth: 1200, margin: "0 auto" }}>
      <div className="derp-comic-pane">
        <h3>{comic.derp.title}</h3>
        <img className="comic-image" alt="derp-comic" src={comic.derp.image} />
        <p>{comic.derp.text}</p>
      </div>
      <SquiggleSeparator className="squiggle" />
      <div className="dandy-comic-pane">
        <h3>{comic.dandy.title}</h3>
        <img
          className="comic-image"
          alt="dandy-comic"
          src={comic.dandy.image}
        />
        <p>{comic.dandy.text}</p>
      </div>
    </div>
  </>
);

const MobileComicPane = ({ comic }) => (
  <>
    <ComicSeparator width="100%" class="comic-separator" />
    <div className="mobile-derp-comic-pane">
      <img className="comic-image" alt="derp-comic" src={comic.derp.image} />
      <h3>{comic.derp.mobileTitle}</h3>
      <p>{comic.derp.text}</p>
    </div>
    <MobileSquiggle className="mobile-squiggle" />
    <div className="mobile-dandy-comic-pane">
      <img className="comic-image" alt="dandy-comic" src={comic.dandy.image} />
      <h3>{comic.dandy.mobileTitle}</h3>
      <p>{comic.dandy.text}</p>
    </div>
  </>
);

export default () => {
  useEffect(() => {
    window.analytics.page("Home");
  }, []);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 750px)" });
  return (
    <>
      <div id="home-page">
        <Helmet>
          <title>Channel - Have a better relationship with your apps</title>
          <link rel="canonical" href="https://channel.af/" />
        </Helmet>
        <h1 className="main-title">
          Have a better relationship
          <br />
          with your apps.
        </h1>
        <h3 className="description">Just a little comic.</h3>
        {isSmallScreen ? (
          <MobileWave width="100%" className="top-wave" />
        ) : (
          <Wave width="100%" className="top-wave" />
        )}
        <div id="comic">
          <h2 className="comic-main-title">Meet Derp and Dandy</h2>
          <div style={{ maxWidth: 1200, margin: "0 auto" }}>
            <div className="intro-column">
              <Derp
                width="148px"
                style={{ marginTop: 21, marginBottom: -21 }}
              />
              <h3>Derp</h3>
              <p>
                Derp is the little part of us that defaults to the easiest
                action, which feels good at the moment. Derp loves to spend
                hours on his phone playing games, scrolling on social media. In
                his mind, there will always be time later to do
                important&nbsp;things.
              </p>
            </div>
            <div className="intro-column">
              <Dandy
                height="148px"
                style={{ marginTop: 7, marginBottom: -7 }}
              />
              <h3>Dandy</h3>
              <p>
                Dandy is the little part of us that takes the action that might
                be more difficult but makes us feel better in the long run.
                Dandy takes a moment to reflect on his actions and asks if they
                are aligned to his goals and best&nbsp;self.
              </p>
            </div>
          </div>
          <h3 className="read-our-comic-header">Read our comic</h3>
          <DownArrow class="down-arrow" />
          {isSmallScreen
            ? comics.map((comic) => <MobileComicPane comic={comic} />)
            : comics.map((comic) => <ComicPane comic={comic} />)}
        </div>
      </div>
      {isSmallScreen ? (
        <>
          <MobileBottomWave width="100%" className="bottom-wave" />
          <BottomNav style={{ marginTop: -90, paddingTop: 120 }} />
        </>
      ) : (
        <>
          <BottomWave className="bottom-wave" width="100%" />
          <BottomNav style={{ marginTop: -50, paddingTop: 80 }} />
        </>
      )}
    </>
  );
};
