import React from "react";
import { Link, withRouter } from "react-router-dom";
const BottomNav = (props) => {
  return (
    <div {...props} className="bottom-navbar">
      <div className="nav-link">
        © {new Date().getFullYear()} Channel Application, LLC
      </div>
      <Link className="nav-link" to="/privacy">
        Privacy policy
      </Link>
      <Link className="nav-link" to="/terms">
        Terms of service
      </Link>
    </div>
  );
};

export default withRouter(BottomNav);
