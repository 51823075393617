import React, { useState, useEffect } from "react";
import { ReactComponent as Ads } from "../resources/articles/ads.svg";
import { ReactComponent as Autoplay } from "../resources/articles/autoplay.svg";
import { ReactComponent as Feed } from "../resources/articles/feed.svg";
import { ReactComponent as History } from "../resources/articles/history.svg";
import { ReactComponent as Notifications } from "../resources/articles/notifications.svg";
import BottomNav from "../components/BottomNav";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

const articleImages = {
  ads: <Ads />,
  autoplay: <Autoplay />,
  feed: <Feed />,
  history: <History />,
  notifications: <Notifications />,
};
export default ({ match }) => {
  useEffect(() => {
    window.analytics.page("Article");
  }, []);
  const history = useHistory();
  const title = match.params.id.replace(/ /g, "-").toLowerCase();
  const [article, setArticle] = useState({});
  useEffect(() => {
    fetch(`https://api-r5xcurhn6a-uc.a.run.app/api/v1/articles/`)
      .then((res) => res.json())
      .then((articles) => {
        const article = articles.find(
          (article) => article.title.replace(/ /g, "-").toLowerCase() === title
        );
        if (article) {
          setArticle(article);
        } else {
          history.push("/");
        }
      });
  }, [history, title]);
  return (
    <div id="article-page">
      {article.id && (
        <Helmet>
          <title>{article.title} - Channel</title>
          <link
            rel="canonical"
            href={`https://channel.af/articles/${match.params.id}`}
          />
          <meta property="og:title" content={article.title} />
          <meta property="og:description" content={article.description} />
          <meta
            property="og:url"
            content={`https://channel.af/articles/${match.params.id}`}
          />
          <meta name="twitter:card" content={article.title} />
          <meta
            property="og:site_name"
            content="Channel - Have a better relationship with your apps"
          />
        </Helmet>
      )}
      <h1 className="main-title article-main-title">{article.title}</h1>
      <p className="article-description">
        <ReactMarkdown source={article.description} />
      </p>
      <p className="article-read-time">
        {article.read_time} {article.id && "minutes to read"}
      </p>
      <div className="article-image">{articleImages[article.photo_url]}</div>
      <p className="article-content">
        <ReactMarkdown source={article.content} />
      </p>
      {!article.id && (
        <CircularProgress style={{ color: "#BB86FC", marginTop: 96 }} />
      )}
      {article.id && <BottomNav />}
    </div>
  );
};
