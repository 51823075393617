import Home from "./HomePage";
import About from "./AboutPage";
import Promises from "./PromisesPage";
import Articles from "./ArticlesPage";
import Article from "./ArticlePage";
import Privacy from "./PrivacyPage";
import Terms from "./TermsPage";
import Slack from "./SlackPage";
import Comic from "./ComicPage";
import Android from "./AndroidPage";

export default {
  Home,
  About,
  Promises,
  Articles,
  Article,
  Privacy,
  Terms,
  Slack,
  Comic,
  Android,
};
