import "./App.css";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Pages from "./pages";
import Nav from "./components/Nav";
import Intercom from "react-intercom";

const currentUrl = window.location.href;
if (
  !currentUrl.includes("channel://") &&
  currentUrl.includes("account/password-reset")
) {
  const deepLinkUrl = currentUrl.replace(
    /^http[A-Z]?:\/\/channel.af\/#/i,
    "channel://"
  );
  window.location.href = deepLinkUrl;
}

export default () => {
  if (window.location.pathname === "/slack") {
    return (
      <>
        <Route exact path="/slack" component={Pages.Slack} />
        <Intercom appID="hizuu05k" />
      </>
    );
  }
  return (
    <>
      <Nav />
      <Switch>
        <Route exact path="/about" component={Pages.About} />
        <Route exact path="/promises" component={Pages.Promises} />
        <Route exact path="/about" component={Pages.About} />
        <Route exact path="/articles" component={Pages.Articles} />
        <Route exact path="/articles/:id" component={Pages.Article} />
        <Route exact path="/privacy" component={Pages.Privacy} />
        <Route exact path="/terms" component={Pages.Terms} />
        <Route exact path="/comic" component={Pages.Comic} />
        <Route exact path="/android" component={Pages.Android} />
        <Route exact path="/" component={Pages.Home} />
        <Redirect from="*" to="/" />
      </Switch>
      <Intercom appID="hizuu05k" />
    </>
  );
};
