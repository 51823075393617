import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as AppStoreButton } from "../resources/app-store-button.svg";
import BottomNav from "../components/BottomNav";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { ReactComponent as Close } from "../resources/close.svg";

export default () => {
  useEffect(() => {
    window.analytics.page("Home");
  }, []);
  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    window.document.body.style.overflow = !showModal ? "hidden" : null;
    setShowModal(!showModal);
  };
  const close = () => {
    window.document.body.style.overflow = null;
    setShowModal(false);
  };
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
  const isSmallScreen = useMediaQuery({ query: "(max-width: 750px)" });
  const imageUrls = [
    "/images/ph-channel-2.png",
    "/images/ph-channel-3.png",
    "/images/ph-channel-4.png",
    "/images/ph-channel-5.png",
    "/images/ph-channel-6.png",
    "/images/ph-channel-7.png",
    "/images/ph-channel-8.png",
    "/images/ph-channel-9.png",
  ];
  return (
    <>
      <div id="home-page">
        <Helmet>
          <meta
            property="og:title"
            content="Channel - Remove distracting features from apps"
          />
          <meta
            property="og:site_name"
            content="Channel - Remove distracting features from apps"
          />
          <meta property="og:url" content="https://channel.af" />
          <meta
            property="og:description"
            content="Hides feeds, auto-play, etc."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="%PUBLIC_URL%/channel-preview.png"
          />
          <title>Channel - Remove distracting features from apps</title>
          <link rel="canonical" href="https://channel.af/" />
        </Helmet>
        <h1 className="main-title">
          Remove distracting
          <br />
          features from apps
        </h1>
        {isMobile ? (
          <a
            className="app-store-button"
            href="https://apps.apple.com/us/app/channel-control-your-apps/id1464464213"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div
              onClick={() =>
                window.analytics.track("Clicked Download App - iOS")
              }
            >
              <AppStoreButton />
            </div>
          </a>
        ) : (
          <div
            className="app-store-button"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.analytics.track("Clicked Download App - iOS - Modal");
              toggle();
            }}
          >
            <AppStoreButton />
          </div>
        )}
        <Link
          className="description"
          style={{
            textDecoration: "underline",
            color: "white",
            marginBottom: 48,
            marginTop: 24,
            cursor: "pointer",
            display: "block",
          }}
          to="/android"
        >
          Get notified for Android
        </Link>
      </div>
      <div
        style={{
          textAlign: "center",
        }}
      >
        {imageUrls.map((url) => (
          <img
            style={{
              margin: "0 auto",
              marginTop: isSmallScreen ? 16 : 32,
              marginBottom: isSmallScreen ? 16 : 32,
              width: "90%",
              maxWidth: 1200,
              display: "block",
            }}
            src={url}
          />
        ))}
      </div>
      {isSmallScreen ? (
        <>
          <BottomNav style={{ marginTop: isSmallScreen ? 8 : 32 }} />
        </>
      ) : (
        <>
          <BottomNav style={{ marginTop: 32 }} />
        </>
      )}
      <div
        style={{
          textAlign: "center",
          overflowY: "scroll",
          marginTop: 0,
          paddingTop: 40,
          position: "absolute",
        }}
        className={`mobile-menu ${showModal ? "show" : "hide"}`}
      >
        <div
          style={{
            position: "absolute",
            cursor: "pointer",
            color: "white",
            right: 0,
            top: 20,
            marginRight: isSmallScreen ? "5%" : "10%",
            background: "#BB86FC",
            borderRadius: "50%",
            height: 40,
            width: 40,
          }}
          onClick={close}
        >
          <Close style={{ padding: 8 }} />
        </div>
        <h1 className="main-title" style={{ marginTop: 0 }}>
          Want Channel on
          <br />
          your iPhone?
        </h1>
        <h3 className="description" style={{ marginBottom: 40 }}>
          On your iPhone, open up the camera app
          <br />
          and hold it up to the QR code.
        </h3>
        <img src="/images/qr-code.png" />
        <hr
          style={{
            borderTop: "1px solid #404040",
            marginTop: 40,
            maxWidth: "70%",
          }}
        />
        <h1 className="main-title" style={{ marginTop: 40, marginBottom: 24 }}>
          Nah
        </h1>
        <h3 className="description" style={{ margin: 0, marginBottom: 32 }}>
          Cool, cool. Here’s the app store
          <br />
          page for desktop.
        </h3>
        <a
          style={{ margin: 0, marginBottom: 40 }}
          className="app-store-button"
          href="https://apps.apple.com/us/app/channel-control-your-apps/id1464464213"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div
            onClick={() => window.analytics.track("Clicked Download App - iOS")}
          >
            <AppStoreButton />
          </div>
        </a>
      </div>
    </>
  );
};
