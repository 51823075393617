import React, { useEffect } from "react";
import BottomNav from "../components/BottomNav";
import { Helmet } from "react-helmet";

const promises = [
  {
    number: "01",
    title: "We make opt-in the default",
    text:
      "We believe in letting you choose what you want to let into your life. We don’t let anyone into our physical homes without our permission. We believe the same should apply in our digital homes.",
  },
  {
    number: "02",
    title: "We give you control over your experience",
    text:
      "We believe that algorithms run our digital life. We also believe that a lot of these algorithms push information and behaviors into our lives we may not want. We want to give you control over how these algorithms affect your digital life.",
  },
  {
    number: "03",
    title: "We will show you what data we collect",
    text:
      "We believe that you have the right to know what information we do collect and why we collect it. You have the right to know how we treat your digital footprints.",
  },
  {
    number: "04",
    title: "We ask, “would we want our kids to use this?”",
    text:
      "We believe that if we feel uncomfortable having our children use Channel, it’s a red flag for an unhealthy product. If a feature doesn’t pass this test, we throw it out.",
  },
  {
    number: "05",
    title: "We won’t sell your data",
    text:
      "We believe that business models that rely on selling your personal information do more harm than good. We would rather go out of business than sell your data.",
  },
  {
    number: "06",
    title: "We won’t optimize for “time in-app” metrics",
    text:
      "We believe that attention is precious. We will never try to get you to spend more of it on us than you want.",
  },
  {
    number: "07",
    title: "We won’t show you advertisements",
    text:
      "We believe that selling ads leads to selling your attention. Selling your attention will probably mean we will have to optimize for “time in-app” and that is something we are not willing to do.",
  },
  {
    number: "08",
    title: "We won’t hold your account hostage",
    text:
      "We believe that you are the owner of your data. If you ever want us to completely delete your account from Channel, we will.",
  },
];
const Promise = ({ number, title, text }) => (
  <div className="promise">
    <div className="promise-number">{number}</div>
    <h3 className="promise-title">{title}</h3>
    <p className="promise-text">{text}</p>
  </div>
);

export default () => {
  useEffect(() => {
    window.analytics.page("Promises");
  }, []);
  return (
    <div id="promises-page">
      <Helmet>
        <title>Channel - Promises</title>
        <link rel="canonical" href="https://channel.af/promises" />
      </Helmet>
      <h1 className="main-title promise-main-title">Our promises to you</h1>
      {promises.map((promise) => (
        <Promise {...promise} />
      ))}
      <div className="block-quote">
        <div className="block-quote-icon">“</div>
        <p className="block-quote-text">
          The fairest rules are those to which everyone would agree if they did
          not know how much power they would have.
        </p>
        <h3 className="block-quote-author">John Rawls</h3>
      </div>
      <BottomNav />
    </div>
  );
};
