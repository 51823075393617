import React, { useEffect } from "react";
import BottomNav from "../components/BottomNav";
import { Helmet } from "react-helmet";

export default () => {
  useEffect(() => {
    window.analytics.page("Slack");
  }, []);
  return (
    <div id="slack-page">
      <Helmet>
        <title>Channel - Slack</title>
        <link rel="canonical" href="https://channel.af/slack" />
      </Helmet>
    </div>
  );
};
