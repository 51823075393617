import React, { useEffect } from "react";
import BottomNav from "../components/BottomNav";
import milestone1 from "../resources/milestones/milestone1.jpg";
import milestone2 from "../resources/milestones/milestone2.jpg";
import milestone3 from "../resources/milestones/milestone3.jpg";
import milestone4 from "../resources/milestones/milestone4.jpg";
import { ReactComponent as LeftDerpArrow } from "../resources/left-derp.svg";
import { ReactComponent as RightDerpArrow } from "../resources/right-derp.svg";
import { Helmet } from "react-helmet";
import { IntercomAPI } from "react-intercom";

const Milestone = ({ img_url, title, date }) => (
  <div className="milestone">
    <img className="milestone-image" alt="milestone" src={img_url} />
    <h3 className="milestone-title">{title}</h3>
    <p className="milestone-date">{date}</p>
  </div>
);
const milestones = [
  {
    img_url: milestone1,
    title: "“Let’s build a company together!”",
    date: "Sep 20, 2018",
  },
  {
    img_url: milestone2,
    title: "Formation of Channel Application, LLC",
    date: "Sep 06, 2019",
  },
  {
    img_url: milestone3,
    title: "Kevin gets our first office",
    date: "Oct 09, 2019",
  },
  {
    img_url: milestone4,
    title: "Launched in the App Store",
    date: "Jan 08, 2020",
  },
];

export default () => {
  useEffect(() => {
    window.analytics.page("About");
  }, []);
  useEffect(() => {
    IntercomAPI("update", { hide_default_launcher: true });
    return () => {
      IntercomAPI("update", { hide_default_launcher: false });
    };
  }, []);
  return (
    <div id="about-page">
      <Helmet>
        <title>Channel - About</title>
        <link rel="canonical" href="https://channel.af/about" />
      </Helmet>
      <h1 className="main-title about-main-title">A tale of two Derps</h1>
      <div className="about-page-intro">
        <p>
          We started Channel because we remembered how exciting it felt when we
          got our first computers and smartphones. It felt like a superpower. We
          could look up anything. We could more easily do our homework, look up
          definitions for words, get more organized, chat with people in super
          niche-forums, and plan our a road-trip with friends. These devices
          felt like they made us better and more informed.
        </p>
        <p>
          But then things started to change, slowly. We were introduced to
          notifications, pings, auto-play, infinite-scroll, algorithm based
          feeds, bait-and-switch sign up forms, and ads that know more about you
          then you do. Our devices went from serving us to us serving them.
        </p>
        <p>
          We believe most apps and websites have implemented these tactics
          because of their business models. Companies like Google, Youtube,
          Facebook, and Instagram make most of their money from advertising.
          They implement these engagement based features because the more time a
          person spends in their app, the more money they make.
        </p>
        <p>
          So what can we do about it? How can we make our devices feel like
          superpowers lifting us up rather than brining us down? With Channel we
          can help people keep the parts of their devices that they find value
          in, and leave behind the parts that distract them.
        </p>
        <p>— Founders, Kevin McAlear and Tom Calabrese</p>
      </div>
      <div className="milestones">
        <div className="derp-left">
          <h4 className="derp-title">Derp</h4>
          <LeftDerpArrow />
        </div>
        <div className="derp-right">
          <h4 className="derp-title">Derp</h4>
          <RightDerpArrow />
        </div>
        {milestones.map((milestone) => (
          <Milestone {...milestone} />
        ))}
      </div>
      <BottomNav style={{ marginTop: 96 }} />
    </div>
  );
};
