import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BottomNav from "../components/BottomNav";
import { ReactComponent as Ads } from "../resources/articles/ads.svg";
import { ReactComponent as Autoplay } from "../resources/articles/autoplay.svg";
import { ReactComponent as Feed } from "../resources/articles/feed.svg";
import { ReactComponent as History } from "../resources/articles/history.svg";
import { ReactComponent as Notifications } from "../resources/articles/notifications.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";

const articleImages = {
  ads: <Ads />,
  autoplay: <Autoplay />,
  feed: <Feed />,
  history: <History />,
  notifications: <Notifications />,
};
const Article = ({ id, title, read_time, photo_url }) => (
  <Link
    to={`/articles/${title.replace(/ /g, "-").toLowerCase()}`}
    className="article"
  >
    {articleImages[photo_url]}
    <div className="article-title">{title}</div>
    <div className="article-read-time">{read_time} minutes to read</div>
  </Link>
);

export default () => {
  useEffect(() => {
    // debugger;
    // window.analytics.page("Articles");
  }, []);
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    fetch("https://api-r5xcurhn6a-uc.a.run.app/api/v1/articles")
      .then((res) => res.json())
      .then((data) => setArticles(data));
  }, []);
  return (
    <div id="blog-page">
      <Helmet>
        <title>Channel - Articles</title>
        <link rel="canonical" href="https://channel.af/articles" />
      </Helmet>
      <h1 className="main-title blog-main-title">Channel Buffet</h1>
      <p className="blog-description">
        A smorgasbord of feelings and opinions on what makes apps so darn
        addictive and what we can all do about it.
      </p>
      {articles.length === 0 && (
        <CircularProgress style={{ color: "#BB86FC" }} />
      )}
      {articles.map((article) => (
        <Article {...article} />
      ))}
      {articles.length !== 0 && <BottomNav />}
    </div>
  );
};
